<template>
    <div class="story">
        <full-scroll></full-scroll>
        <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
            <div class="flex flex-pack-center flex-1">
                <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">{{ $route.query.sourceType == 4 ? '心愿墙' : '政策法律宣传' }}</div>
            </div>
            <div class="flex-end flex-1 flex" @click="backHome">
                <div class="my_text_center">
                    <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome m_b10" />
                    <p>返回首页</p>
                </div>
            </div>
        </div>

        <div class="msg-mid flex  flex-justify-center flex-pack-center">>
            <div class="feedback-left">
                <div class="
                            font-size28 font-blod
                            feedback-left-tot-title
                            color_FF
                            my_text_center
                            m_b10
                        ">
                    心愿墙
                </div>
                <div class="font-size24 feedback-left-tot-box">
                    <div class=" color_FF padding10 font-blod border-bottom actived">
                        全部
                    </div>
                </div>
            </div>
            <div class="notice-box">

                <div class="notice-box-list" id="notice-box-list">
                    <ul>
                        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                            <li class="notice-list flex" v-for="(item, id) in newAdverList" :key="id"
                                @click="goToDetail(item)">
                                <div class="m_r20" v-if="item.cover != null && item.cover != ''">
                                    <img :src="item.cover" alt="" />
                                </div>

                                <div class="
                                        flex-1
                                        m_r20
                                        flex flex-v flex-justify-center
                                    ">
                                    <div class="notice-list-box color_FF">
                                        <p class="font-size24 m_b20">
                                            {{ item.title }}
                                        </p>
                                        <p class="font-size22 color_9FB">
                                            {{ item.releaseTime }}
                                        </p>
                                    </div>
                                </div>
                                <div class="
                                        check-info
                                        flex
                                        flex-justify-center
                                        flex-pack-center
                                    ">

                                    <span v-if="$route.query.sourceType == 4" class="font-size18 color_FF">查看详情</span>
                                </div>
                            </li>
                        </van-list>
                    </ul>
                </div>
            </div>

        </div>

        <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
            <div class="my_text_center" @click="goBack">
                <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF">
            </div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { getNewsList } from "@/request/api.js";
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
    components: { fullScroll },
    data() {
        return {
            sourceType: '',//传的参数id
            isActived: 0,
            activeNames: 1,
            leaderTypeList: [
                {
                    id: 1,
                    name: '政策法律宣传',
                    child: [
                        {
                            id: 1,
                            name: '政策法律宣传'
                        },
                    ]
                },
                {
                    id: 2,
                    name: '立法意见收集站',
                    child: [
                        {
                            id: 0,
                            name: '全部'
                        },
                        {
                            id: 1,
                            name: '未开始'
                        }, {
                            id: 2,
                            name: '进行中'
                        },
                        {
                            id: 3,
                            name: '已完成'
                        }
                    ]
                }
            ],
            loading: false,
            finished: false,
            totalPage: null,
            todayMtime: "",
            todayTime: "",
            newAdverList: [],
            pageNum: 1,
            pageSize: 6,
            scrollTop: 0,
        };
    },
    created() {
        this.getTime();
        this.sourceType = this.$route.query.sourceType;
    },
    activated() {
        document.getElementById("notice-box-list").scrollTop = this.scrollTop; // 设置滚动条位置
    },

    mounted() {
        this.getFetch();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    methods: {
        /**
         * 获取时间
         */
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        /***
         *  获取心愿墙列表
         */
        getFetch() {
            getNewsList({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                tid: 20
            }).then((res) => {
                this.loading = false;
                if (res.code == 200) {
                    let data = res.data.list;
                    if (data.length == 0) {
                        this.finished = true;
                    } else {
                        if (this.pageNum == res.data.totalPage) {
                            this.finished = true;
                        } else {
                            this.finished = false;
                        }
                        data.forEach((item) => {
                            item.releaseTime = item.releaseTime.split(" ")[0];
                        });
                        this.totalPage = res.data.totalPage;
                        this.newAdverList.push(...data);
                    }
                }
            });
        },

        onLoad() {
            if (this.totalPage <= this.pageNum) {
                this.loading = false;
                this.finished = true;
            } else {
                this.loading = true;
                this.pageNum++;
                this.getFetch();
            }
        },

        goToDetail(data) {
            this.scrollTop = document.getElementById("notice-box-list").scrollTop;
            let Data = JSON.stringify(data);
            this.$router.push({
                name: "supervisedetail",
                params: { data: encodeURIComponent(Data), type: 99 },
            });
        },
        //返回首页
        backHome() {
            this.$router.push("/");
        },
        goBack() {
            this.$router.back();
        },
    },

    /**
    * 缓存的页面
    */
    beforeRouteLeave(to, from, next) {
        if (to.path === "/supervisedetails") {
            from.meta.keepAlive = true;
        } else {
            from.meta.keepAlive = false;
        }
        next();
    },
};
</script>
